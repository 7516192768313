<template>
  <div>
    <el-popover
        placement="right"
        title=""
        width="500"
        trigger="click"
    >
      <el-button :size="size" type="default" slot="reference">
        <span v-if="currentQuarter">{{ currentQuarterLabel }}</span>
        <span v-else>选择季度</span>
      </el-button>
      <el-row>
        <el-col :span="24">
          <el-radio-group v-model="currentQuarter">
            <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
              {{ quarter.label }}
            </el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "QuarterSelect",
  props: {
    quarter: {
      type: Number,
      default() {
        return 0
      }
    },
    size: {
      type: String,
      default() {
        return 'small';
      }
    }
  },
  computed: {
    currentQuarter: {
      get() {
        return this.quarter
      },
      set(val) {
        this.$emit('update:quarter', val)
        this.$emit('change')
      }
    },
    currentQuarterLabel() {

      let quarterObj = this.quarters.find(value => value.value === this.currentQuarter)
      return quarterObj.label
    }
  },
  data() {
    return {
      quarters: [
        {label: '未选季度', value: 0},
        {label: '第一季度', value: 1},
        {label: '第二季度', value: 2},
        {label: '第三季度', value: 3},
        {label: '第四季度', value: 4},
      ],
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>