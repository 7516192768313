<template>
  <div>
    <!--  绩效的分组-->
    <el-select v-model="currentGroupId" placeholder="绩效分组" clearable>
      <el-option v-for="(item,index) in options" :value="item.value" :label="item.label" :key="index">
        <span style="float: left"> {{ item.label }} </span>
        <span style="float: right"> {{ item.value }} </span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "PerformanceGroupSelect",
  props: {
    groupId: {
      type: [Number, String],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {year: null, quarter: null};
      }
    }
  },
  computed: {
    currentGroupId: {
      get() {
        return this.groupId
      },
      set(val) {
        this.$emit('update:groupId', val)
        this.$emit('change')
      }
    }
  },
  watch:{
    searchCondition:{
      deep:true,
      immediate:true,
      handler(){
        this.getGroupList()
      }
    }
  },
  data() {
    return {
      options: []
    }
  },
  methods: {
    async getGroupList() {
      this.options = []
      //获取所有的分组（提交过绩效数据的）
      let {list} = await this.$api.getGroupListOfPerformanceRevenue(this.searchCondition)
      this.$nextTick(() => {
        this.options = list;
      })

    }
  },
  created() {
    // this.getGroupList()
  }
}
</script>

<style scoped>

</style>