<template>
  <div class="default-table">
    <el-table
        :data="tblData"
        :span-method="objectSpanMethod"
        :cell-class-name="cellClassName"
        :summary-method="getSummaries"
        show-summary
        style="width: 100%"
        border>
      <el-table-column label="分组" prop="group_name" align="center" sortable></el-table-column>
      <el-table-column
          prop="nickname"
          label="红人昵称"
          align="center"
          min-width="100" sortable>
      </el-table-column>
      <el-table-column
          prop="platform_name"
          label="平台"
          align="center"
          min-width="90">
        <template slot-scope="{row}">
          <span>{{ row.platform_name || '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="income_type"
          label="收入分类"
          align="center"
          min-width="90">
        <template slot-scope="{row}">
          <span>{{ row.income_type || '/' }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="average_value"-->
      <!--          label="上季月营收均值"-->
      <!--          min-width="110" header-align="center" align="center">-->
      <!--        <template slot-scope="{row}">-->
      <!--          <span>{{ row.average_value||'/' }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column v-for="(month,index) in quarterlyMonths" :key="index" :label="`${month}月`"
                       align="center">
        <el-table-column
            :prop="`quotient_${month}`"
            :key="`quotient_${month}`"
            label="系数"
            min-width="80" header-align="center" align="center">
          <template slot-scope="{row}">
            <span>{{ row[`quotient_${month}`] || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :prop="`flag_value_${month}`"
            :key="`flag_value_${month}`"
            label="目标"
            min-width="100" header-align="center" align="right">
          <template slot-scope="{row}">
            <span>{{ moneyFormat(row[`flag_value_${month}`]) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :prop="`real_value_${month}`"
            label="完成"
            min-width="120" header-align="center" align="right" :key="`real_value_${month}`">
          <template slot-scope="{row}">
             <span :class="[row[`real_value_${month}`]>row[`flag_value_${month}`]?'gt':'lt']"
                   v-if="row[`real_value_${month}`] != row[`flag_value_${month}`]">
               <i :class="[row[`real_value_${month}`]>row[`flag_value_${month}`]?'el-icon-top':'el-icon-bottom']"></i>
               {{ valFormat(row[`real_value_${month}`]) }}
             </span>
            <span v-else>{{ moneyFormat(row[`real_value_${month}`]) }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="`季度合计`" prop="real_total" key="real_total" header-align="center" align="right">
        <template slot-scope="{row}">
          <span>{{ moneyFormat(row.real_total) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="`月营收均值`" prop="real_avg" key="real_avg" header-align="center" align="right">
        <template slot-scope="{row}">
          <span>{{ moneyFormat(row.real_avg) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {numberFormat} from "@/utils/utils";

export default {
  name: "RevenueHistoryPanel",
  props: {
    quarterlyMonths: {
      type: Array
    },
    dataList: {
      type: Array
    }
  },
  computed: {
    tblData() {
      return this.dataList.map((item) => {
        item.real_avg = item.real_total / 3
        return item
      });
    },
  },
  watch: {
    quarterlyMonths: {
      immediate: true,
      deep: true,
      handler() {
        this.getCalcFields()
      }
    },
    dataList: {
      immediate: true,
      deep: true,
      handler() {
        this.rowspan()
      }
    }
  },
  data() {
    return {
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      mergeFields: ['group_name', 'nickname', 'platform_name'],//合并的字段
      fixFields: ['group_name', 'nickname', 'platform_name', 'income_type'],//average_value
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],
    }
  },
  methods: {
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (['real_total','real_avg'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);

            sums[index] += '';
          } else {
            sums[index] = 'N/A';
          }

          switch (column.property) {
            case 'real_total':
              sums[index] = this.moneyFormat(sums[index])
                  break
            case 'real_avg':
              sums[index] = this.moneyFormat(sums[index])
              break;
          }
        }
      });

      return sums;
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    rateFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    getCalcFields() {
      this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1;
              }
            })
          }
        })
      }
      return calcRow
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },

    // eslint-disable-next-line
    objectSpanMethod({row, column, rowIndex, columnIndex}) {

      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property] ? this.spanObj[column.property][rowIndex] : 0
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({row, column, rowIndex, columnIndex}) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_');
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    }
  },
  created() {
    this.getCalcFields()
  }
}
</script>

<style scoped>
.gt {
  color: #ff0000;
}

.lt {
  color: #10af88;
}
</style>